import React, { useEffect } from "react";
import "style.css";
import "tailwindcss/dist/base.css";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Hero from "components/hero/BackgroundAsImage";
import ContactUs from "components/forms/SimpleContactUs";
import Footer from "components/footers/MiniCenteredFooter";
import FAQ from "components/faqs/TwoColumnPrimaryBackground";
import Features from "components/features/ThreeColSimple";
import MissionVision from "components/hero/MissionVision";
import { useLocation } from "react-router-dom";

function MainLandingPage() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <AnimationRevealPage>
      <Hero />
      <Features />
      <FAQ />
      <ContactUs />
      <Footer />
    </AnimationRevealPage>
  );
}

export default MainLandingPage;
