/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)((props) => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center  leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`,
]);
const VisionStatement = tw.p`text-center text-black font-bold text-lg w-80 md:min-w-2xl lg:max-w-3xl mt-8`;
const SubmitButton = tw(PrimaryButtonBase)` lg:ml-6 mt-6 lg:mt-0 text-xl `;

const LeftColumn = tw.div`flex flex-col items-center lg:block`;
export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Designed & Developed by <span tw="text-primary-500">Professionals.</span>
    </>
  ),
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  primaryButtonText = "Learn More",
  primaryButtonUrl = "https://timerse.com",
  imageSrc = TeamIllustrationSrc,
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  let submitButtonText = "Subscribe";
  let formAction =
    "https://gmail.us5.list-manage.com/subscribe/post?u=480dd1caa870b3a0d9ba85efe&amp;id=8c883e9428";
  let formMethod = "post";
  const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`;
  return (
    <Container>
      <TwoColumn>
        <LeftColumn>
          {/*<Notification>Join now to start earning $$$</Notification>*/}
          <Heading>
            Our <span tw="text-primary-500">Vision</span>
          </Heading>
          <VisionStatement>
            At Notio we envision a socially responsible and economically
            conscious social media platform that revolutionizes the way ideas
            are shared and validated. Our mission is to foster an inclusive
            community where creativity and collaboration thrive, without relying
            on vanity or algorithms for validation. Instead, we harness the
            renewable resource of people's ideas and collective wisdom to
            empower individuals, promote positive engagement, and drive
            meaningful change.
          </VisionStatement>
        </LeftColumn>
        {/* <Form action={formAction} method={formMethod} noValidate>
          <SubmitButton
            type="submit"
            value="Subscribe"
            name="subscribe"
            id="mc-embedded-subscribe"
          >
            {submitButtonText}
          </SubmitButton>
        </Form> */}
        {/* <RightColumn></RightColumn> */}
      </TwoColumn>
    </Container>
  );
};
