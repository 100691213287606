/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../misc/Buttons.js";
import { useNavigate } from "react-router-dom";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;
const ValueDescription = styled.div`
  ${tw`flex flex-col items-center lg:block mt-12`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10  border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-400`}
  }

  .description {
    ${tw`mt-3 font-bold text-black text-sm leading-loose`}
  }
`;
const SubmitButton = tw(
  PrimaryButtonBase
)` mx-auto text-xl flex flex-col items-center `;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/about");
  };
  const cards = [
    {
      // imageSrc: ShieldIconImage,
      title: "Empowering Ideas",
      description:
        "We believe that every idea has the potential to make a difference. Our platform provides an environment where diverse perspectives are encouraged, and ideas are validated based on their impact and merit rather than popularity",
    },
    {
      imageSrc: SupportIconImage,
      title: "Community-Driven Validation",
      description:
        "Users' contributions are essential in shaping the platform. We place value on community feedback and engagement, empowering individuals to participate in the decision-making process.",
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Sustainable and Ethical",
      description:
        "Our commitment to sustainability extends beyond renewable resources. We also ensure ethical practices by respecting users' privacy, promoting responsible content moderation, and fostering a positive online environment.",
    },
    {
      imageSrc: ReliableIconImage,
      title: "Fair Compensation",
      description:
        "We recognize the value of individuals' intellectual contributions and compensate users fairly for their ideas, encouraging further creativity and knowledge-sharing.",
    },
    {
      imageSrc: FastIconImage,
      title: "Positive Impact",
      description:
        " By promoting a nurturing and supportive space, we aim to drive positive societal change through collaborative efforts and collective intelligence.",
    },
    {
      imageSrc: SimpleIconImage,
      title: "Authentic Connection",
      description:
        "We prioritize genuine human connections over superficial metrics, fostering a healthier and more fulfilling social media experience for our users",
    },
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>
          <span>Our</span>
          <span tw="text-primary-500"> Values</span>
          <p tw="text-base max-w-2xl mx-auto">
            Through these core principles, Notio aims to lead the way in
            transforming social media into a force for good, where ideas can
            truly change lives and the world.
          </p>
        </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              {/* <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span> */}
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <SubmitButton
        type="submit"
        value="Contact Us"
        name="Contact Us"
        onClick={() => handleNavigation()}
      >
        Learn More
      </SubmitButton>
    </Container>
  );
};
