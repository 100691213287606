import React, { useState } from "react";
import SimpleContactUs from "components/forms/SimpleContactUs";
import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";
import tw from "twin.macro";
import styled from "styled-components";
import "../../css/curves.css";
import { PrimaryButton as PrimaryButtonBase } from "../misc/Buttons.js";
import Features from "components/features/ThreeColSimple";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-900 hover:border-gray-300 hover:text-gray-600 text-xl z-50`}
  }

  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-300 z-50`}
  }
`;
const SlantedBackground = styled.span`
  ${tw`relative text-primary-300 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;
const Heading = styled.h1`
  ${tw`text-4xl text-center sm:text-5xl lg:text-5xl xl:text-6xl font-black text-gray-100 text-black leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover bg-gradient-to-b from-green-200 to-green-600`} h-full 
  background-image: url("https://testvideoupload-1.s3.us-west-1.amazonaws.com/negative-space-abstract-turbulent-sphere-2.jpg");
`;
const OpacityOverlay = tw.div` absolute inset-0 bg-gradient-to-b from-green-200 to-green-500 h-[36rem]`;
const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div` pt-16 pb-32 px-4 flex justify-between items-center flex-col`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const MissionStatement = tw.p`text-center text-white font-bold text-lg w-80 md:min-w-2xl lg:max-w-3xl mt-8`;
// const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const MissionVision = () => {
  const handleScroll = () => {
    const contactForm = document.querySelector(".contact-form");
    if (contactForm) {
      contactForm.scrollIntoView({ behavior: "smooth" });
    }
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/about">What is Notio?</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      {/* <PrimaryLink href="/#"> */}
      {/*  Hire Us*/}
      {/* </PrimaryLink> */}
    </NavLinks>,
  ];

  let submitButtonText = "Contact Us";
  let formAction = "http://eepurl.com/itdLO-/";
  let formMethod = "post";
  const Form = tw.div`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`;
  const Input = tw.input`border-2 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;

  const SubmitButton = tw(PrimaryButtonBase)` lg:ml-6 mt-6 lg:mt-0 text-xl `;
  return (
    <Container style={styles.container}>
      {/* <OpacityOverlay /> */}
      <div class="custom-shape-divider-bottom-1686636061">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
            {/*<Notification>Join now to start earning $$$</Notification>*/}
            <Heading>
              <span>Where Creativity Meets</span>
              <br />
              <SlantedBackground> Community.</SlantedBackground>
            </Heading>
            <MissionStatement>
              At Notio we envision a socially responsible and economically
              conscious social media platform that revolutionizes the way ideas
              are shared and validated. Our mission is to foster an inclusive
              community where creativity and collaboration thrive, without
              relying on vanity or algorithms for validation. Instead, we
              harness the renewable resource of people's ideas and collective
              wisdom to empower individuals, promote positive engagement, and
              drive meaningful change.
            </MissionStatement>
          </LeftColumn>
          <Form action={formAction} method={formMethod} noValidate>
            <SubmitButton
              type="submit"
              value="Contact Us"
              name="Contact Us"
              onClick={() => handleScroll()}
            >
              {submitButtonText}
            </SubmitButton>
          </Form>
          {/* <RightColumn></RightColumn> */}
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    padding: "60px",
    lineHeight: "1.8",
    color: "#2c3e50",
    // background: "green",
    // borderRadius: "15px",
  },
  //   header: {
  //     color: "#ffffff",
  //     borderBottom: "3px solid #38a169",
  //     paddingBottom: "15px",
  //     fontSize: "2.2em",
  //     marginBottom: "50px",
  //     borderRadius: "10px",
  //   },
  //   subHeader: {
  //     color: "#ffffff",
  //     marginBottom: "20px",
  //     fontSize: "1.7em",
  //     borderRadius: "8px",
  //   },
  //   paragraph: {
  //     fontSize: "1.1em",
  //   },
  //   section: {
  //     marginBottom: "60px",
  //     background: "linear-gradient(135deg, #ffffff, #e6f8e0)", // Gradient background
  //     padding: "20px",
  //     borderRadius: "15px",
  //     boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
  //   },
  //   list: {
  //     listStyleType: "none",
  //     paddingLeft: "0",
  //   },
  //   listItem: {
  //     marginBottom: "15px",
  //     position: "relative",
  //     paddingLeft: "20px",
  //     fontSize: "1.1em",
  //   },
  //   listItemBefore: {
  //     content: '""',
  //     position: "absolute",
  //     left: 0,
  //     top: "50%",
  //     transform: "translateY(-50%)",
  //     height: "6px",
  //     width: "6px",
  //     borderRadius: "50%",
  //     backgroundColor: "#38a169",
  //   },
};

export default MissionVision;
