/* eslint-disable no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReactModalAdapter from "../../helpers/ReactModalAdapter";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import "../../css/curves.css";
const Heading = tw(SectionHeading)`text-gray-900 md:w-[30rem] mx-auto`;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto text-black`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto bg-gradient-to-b from-green-200 to-green-500`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-black`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;
const VerticalSpacer = tw.div`mt-10 w-full`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50 w-144 h-144 m-auto`}
  }

  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-pink-200 outline-none`}
  }

  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

export default ({
  cards = [
    {
      imageSrc: ShieldIconImage,
      title: "E-Pitch",
      description:
        "Users explain their idea to the world by creating a short video right on the app",
      featureDescription: "hello world",
    },
    {
      imageSrc: SupportIconImage,
      title: "Voting",
      description:
        "Notio uses a large pool of voters to determine which ideas are most popular. Users watch 40 second e-pitches then swipe to vote. ",
      featureDescription: "hello world",
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Founder pairing",
      description:
        "The voting sequence isolates the most popular concepts, some are chosen to become companies and paired up with highly skilled founder teams which are hand picked based on relevant experience. ",
      featureDescription: "hello world",
    },
    {
      imageSrc: ReliableIconImage,
      title: "Crowdfunding",
      description:
        "Once a concept is paired with a founder team it is then crowdfunded on the same network that posted it and voted on it - the users become owners of every startup Notio launches by investing as little as $50 in any startup that Notio launches",
      featureDescription: "hello world",
    },
    {
      imageSrc: FastIconImage,
      title: "Passive Income",
      description:
        "\tVoting - Users earn up to $15/hr just for voting \n" +
        "\n" +
        "\tPosting - Users that post popular concepts are paid \tbased on the number of ads their post generates \t(like influencers on YouTube)",
      featureDescription: "hello world",
    },
    // { imageSrc: SimpleIconImage, title: "Easy" },
  ],
  linkText = "Learn More",
  heading = "What is Notio and how does it work?",
  subheading = "",
  description = "    Notio is the social media app for innovation which provides its users with new ways to easily earn consistent money from anywhere as well as providing attainable opportunities for our users to generate passive income and long term wealth.\n",
  imageContainerCss = null,
  imageCss = null,
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [featureHeader, setFeatureHeader] = useState("");
  const [featureContent, setFeatureContent] = useState("");
  const toggleModal = () => modalContent(featureHeader, featureContent);
  const modalContent = (featureHeader, featureContent) => {
    setModalIsOpen(!modalIsOpen);
    setFeatureHeader(featureHeader);
    setFeatureContent(featureContent);
  };
  return (
    <Container>
      <ContentWithPaddingXl>
        {/*{subheading && <Subheading>{subheading}</Subheading>}*/}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <ThreeColumnContainer>
          <VerticalSpacer />
          {cards.map((card, i) => (
            <Column key={i}>
              {/*<Card onClick={ () => modalContent(card.title, card.featureDescription)} href={card.url}>*/}
              <Card href={card.url}>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss} />
                </span>
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
                {/*{linkText && (*/}
                {/*  <span className="link">*/}
                {/*    <span>{linkText}</span>*/}
                {/*    <ArrowRightIcon className="icon" />*/}
                {/*  </span>*/}
                {/*)}*/}
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
      <StyledModal
        closeTimeoutMS={300}
        className="mainHeroModal"
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        shouldCloseOnOverlayClick={true}
      >
        <CloseModalButton onClick={toggleModal}>
          <CloseIcon tw="w-6 h-6" />
        </CloseModalButton>
        <div className="content">
          <ContentWithPaddingXl>
            {heading && <Heading>{featureHeader}</Heading>}
            {description && <Description>{featureContent}</Description>}
          </ContentWithPaddingXl>
        </div>
      </StyledModal>
    </Container>
  );
};
