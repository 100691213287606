/* eslint-disable no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import "../../css/curves.css";
import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";
import TwoColumnWithImageAndRating from "../testimonials/TwoColumnWithImageAndRating";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import { PrimaryButton as PrimaryButtonBase } from "../misc/Buttons";
import ReactModalAdapter from "../../helpers/ReactModalAdapter";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as ArrowLeftIcon } from "../../images/arrow-left-3-icon.svg";
import { ReactComponent as ArrowRightIcon } from "../../images/arrow-right-3-icon.svg";
import Slider from "react-slick";
import { ReactComponent as StarIconBase } from "../../images/star-icon.svg";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-900 hover:border-gray-300 hover:text-gray-600 text-xl`}
  }

  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-300`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`} h-full 
  background-image: url("https://testvideoupload-1.s3.us-west-1.amazonaws.com/negative-space-abstract-turbulent-sphere-2.jpg");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-gradient-to-b from-green-200 to-green-500 lg:h-[36rem]`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div` pt-16 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 text-black leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-300 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  display: none;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;
const TestimonialSlider = styled(Slider)`
  ${tw` md:w-auto text-center md:text-left flex justify-center items-center`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;

const Testimonial = tw.div`outline-none  flex flex-col border-4 p-4 border-primary-500 justify-center items-center bg-gray-300`;
const Quote = tw.blockquote`mt-10 mb-8 p-2  sm:mb-10 leading-relaxed font-bold text-black text-xl flex justify-center items-center`;

const CustomerInfoAndControlsContainer = tw.div`mt-auto flex justify-center items-center flex-col sm:flex-row`;

const Controls = styled.div`
  ${tw`flex mt-2 sm:mt-0 mb-6`}
  .divider {
    ${tw`my-3 border-r`}
  }
`;
const ControlButton = styled.button`
  ${tw`mx-3 p-2 rounded-full transition duration-300 bg-gray-200 hover:bg-gray-300 text-primary-300 bg-white hover:text-primary-700 focus:outline-none focus:shadow-outline`}
  svg {
    ${tw`w-3 h-3 stroke-4`}
  }
`;
const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }

  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-pink-200 outline-none`}
  }

  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-300`;

export default () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">About us</NavLink>
      {/* <NavLink href="#">Blog</NavLink>
      <NavLink href="#">Locations</NavLink>
      <NavLink href="#">Pricing</NavLink> */}
    </NavLinks>,
    <NavLinks key={2}>
      {/* <PrimaryLink href="/#"> */}
      {/*  Hire Us*/}
      {/* </PrimaryLink> */}
    </NavLinks>,
  ];

  let submitButtonText = "Subscribe";
  let formAction =
    "https://gmail.us5.list-manage.com/subscribe/post?u=480dd1caa870b3a0d9ba85efe&amp;id=8c883e9428";
  let formMethod = "post";
  const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`;
  const Input = tw.input`border-2 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;

  const SubmitButton = tw(PrimaryButtonBase)` lg:ml-6 mt-6 lg:mt-0 text-xl `;
  const [sliderRef, setSliderRef] = useState(null);
  const testimonials = [
    {
      quote: `Notio, the marketplace for ideas`,
    },
    {
      quote: `Notio decentralizes R&D!`,
    },
    // {
    //   quote: `Notio creates opportunity for long term wealth!`,
    // },
    // {
    //   quote: `"Every revolution begins with a single act of defiance." -Gandhi `,
    // },
    {
      quote: `The next generation of social media`,
    },
    {
      quote: `Share your great ideas with the world`,
    },
  ];
  return (
    <Container>
      <OpacityOverlay />
      <div class="custom-shape-divider-bottom-1686636061">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
            {/*<Notification>Join now to start earning $$$</Notification>*/}
            <Heading>
              <span>Sign up below for</span>
              <br />
              <SlantedBackground>Early Access.</SlantedBackground>
              <Form action={formAction} method={formMethod} noValidate>
                {/*<div aria-hidden="true"><input type="text" name="b_480dd1caa870b3a0d9ba85efe_8c883e9428" tabIndex="-1" value=""/>*/}
                {/*</div>*/}
                {/*<Input type="email" name="email" placeholder="Your Email Address" id="mce-EMAIL"/>*/}
                <SubmitButton
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                >
                  {submitButtonText}
                </SubmitButton>
              </Form>
            </Heading>
          </LeftColumn>
          <RightColumn>
            <StyledResponsiveVideoEmbed
              url="//player.vimeo.com/video/374265101?title=0&portrait=0&byline=0&autoplay=0&responsive=1"
              background="transparent"
            />

            <TestimonialSlider
              arrows={false}
              autoplay={true}
              fade={true}
              centerMode={true}
              pauseOnHover={true}
              // autoplay={2500}
              ref={setSliderRef}
            >
              {testimonials.map((testimonial, index) => (
                <Testimonial key={index}>
                  {/*<TestimonialHeading>{testimonial.heading}</TestimonialHeading>*/}
                  <Quote>{testimonial.quote}</Quote>
                  <CustomerInfoAndControlsContainer>
                    {/*<CustomerInfo>*/}
                    {/*    <CustomerProfilePicture src={testimonial.profileImageSrc} alt={testimonial.customerName} />*/}
                    {/*    <CustomerTextInfo>*/}
                    {/*        <CustomerName>{testimonial.customerName}</CustomerName>*/}
                    {/*        <CustomerTitle>{testimonial.customerTitle}</CustomerTitle>*/}
                    {/*    </CustomerTextInfo>*/}
                    {/*</CustomerInfo>*/}
                    <Controls>
                      <ControlButton onClick={sliderRef?.slickPrev}>
                        <ArrowLeftIcon />
                      </ControlButton>
                      <div className="divider" />
                      <ControlButton onClick={sliderRef?.slickNext}>
                        <ArrowRightIcon />
                      </ControlButton>
                    </Controls>
                  </CustomerInfoAndControlsContainer>
                </Testimonial>
              ))}
            </TestimonialSlider>
          </RightColumn>
        </TwoColumn>
      </HeroContainer>

      <StyledModal
        closeTimeoutMS={300}
        className="mainHeroModal"
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        shouldCloseOnOverlayClick={true}
      >
        <CloseModalButton onClick={toggleModal}>
          <CloseIcon tw="w-6 h-6" />
        </CloseModalButton>
        <div className="content">
          <span>QR CODE HERERERER</span>
        </div>
      </StyledModal>
    </Container>
  );
};
