import React from "react";
import MissionVision from "components/hero/MissionVision";
import Values from "components/features/DashedBorderSixFeatures";
import VisionStatement from "components/features/TwoColWithButton";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import SimpleContactUs from "components/forms/SimpleContactUs";
import Subscribe from "components/forms/SimpleSubscribeNewsletter";
function MissionPage() {
  return (
    <AnimationRevealPage>
      <MissionVision />
      <VisionStatement
        imageSrc=""
        heading="Our Vision"
        description="We aspire to be the leading platform that prioritizes the well-being and genuine connection of our users. By eliminating the pursuit of likes and prioritizing the validation of ideas through community support, we aim to create a space where authenticity, creativity, and innovation flourish. Through our commitment to sustainability, we ensure that the collective intelligence of our community benefits both individuals and society at large. Furthermore, we actively invest in our users by providing multiple avenues for fair compensation, recognizing and valuing their contributions to a more enlightened and empowered world."
      />
      <Subscribe />
      <Values />
      <SimpleContactUs />
    </AnimationRevealPage>
  );
}

export default MissionPage;
