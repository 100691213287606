import React, { useEffect } from "react";
import "style.css";
import "tailwindcss/dist/base.css";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Hero from "components/hero/BackgroundAsImage";
import ContactUs from "components/forms/SimpleContactUs";
import Footer from "components/footers/MiniCenteredFooter";
import FAQ from "components/faqs/TwoColumnPrimaryBackground";
import Features from "components/features/ThreeColSimple";
import MissionPage from "MissionPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainLandingPage from "./MainLandingPage";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/about" element={<MainLandingPage />} />
        <Route path="/" element={<MissionPage />} />
        {/* <Route
            path="/components/:type/:subtype/:name"
            element={<ComponentRenderer />}
          />
          <Route
            path="/components/:type/:name"
            element={<ComponentRenderer />}
          />
          <Route path="/thank-you" element={<ThankYouPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
