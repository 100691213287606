/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(
  Container
)`-mx-8 px-8  text-gray-100 text-black`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)`text-gray-700`;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-600`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-primary-500 hover:border-primary-900 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-sm font-medium mt-4 text-black`;

export default ({
  subheading = "",
  heading = "Frequently Asked Questions",
  description = "",
  faqs = [
    {
      question:
        "Why would I post my valuable idea on a public network? Won’t It get stolen? ",
      answer:
        "Because it is by far your best bet to see any monetary return on your idea. We assume all of the risk and do all of the work for people who aren’t capable or don’t want to do the work. Unless you’re willing to pour an unimaginable amount of resources, sacrifice and about a decade of your life turning your idea into a business, you should take your chances on Notio. Even with the time and resources necessary to turn your idea into a business, 90% of them still fail. Look it up. \n" +
        "When you post your idea on Notio it will be fairly evaluated and you could earn money on your idea even if it never becomes a business. If your idea does become a business, you will receive checks as long as the company is net profit. ",
      // answerLine2: " You should know though, that more than 90% of all businesses fail, most of them fail after the founders put just about everything they have into what amounts to a huge risk, founders pour astonishing amounts of work, time, energy, and money into their startups and still… an overwhelming majority fail, especially in the case of first time founders. If, however, you’re like the large majority of people who, realistically  just can’t take these kinds of risks and need to keep working on earning money for your family but you also have ideas and you’d prefer to make some money with them as opposed to just doing/earning nothing with them, then Notio is definitely your best bet.",
      // answerLine3: " We will make sure your idea gets fairly evaluated and we will pay you if it starts to generate significant volume. You will also be compensated if it turns into a startup and gets funded, you will always be a part of it and will continue receiving checks as long as the business generates net positive revenue."
    },
    {
      question: "What is the referral reward program?",
      answer:
        "Our way to thank you for sharing Notio with your friends and family is to give you money to invest in our startups. When you submit your email on our landing page we send you a unique QR code/link, which works as your referral ID. Show your friends your QR code and when they sign up using your QR code and become active, we reward you with Equidollars which are used to invest in Notio’s startups on the crowdfunding platform. Spending Equidollars is how our users acquire true ground floor equity in the startups that are crowdfunded on Notio’s platform.",
    },
    {
      question: "How can I become an investor in Notio?",
      answer:
        "Please email us at, founderteam@thenotioapp.com with investment inquiries. We will send you a personal reply, thank you for your interest. ",
    },
    {
      question: "How do I earn money for voting? ",
      answer:
        "Voting on one idea takes about 60sec, for the first 40sec, users just watch the video explaining the idea, then they vote on the idea and see a 5-10sec ad. The ads generate revenue, which we then take and give some back to you. We literally only earn revenue when you do. This will allow us to pay our users up to $15hr and more depending on number of users.",
    },
    {
      question: "How do I earn money for posting ideas? ",
      answer:
        "Once you post an idea it automatically gets voted on twenty times in the first round. The top ten percent of ideas posted in round one, move on to round two where they receive two hundred-fifty votes each, the top ten percent of those move on to round three, ideas that make it to around round three start to generate income for their OP (original poster). The further your idea makes it into the voting sequence, the more you make! ",
    },
  ],
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 },
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: {
              opacity: 1,
              height: "auto",
              marginTop: "16px",
              display: "block",
            },
            collapsed: {
              opacity: 0,
              height: 0,
              marginTop: "0px",
              display: "none",
            },
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
          {/*{faq.answerLine2 ?*/}
          {/*    <span>*/}
          {/*        <br/>*/}
          {/*        <br/>*/}
          {/*        {faq.answerLine2}*/}
          {/*    </span> :*/}
          {/*    null}*/}
          {/*{faq.answerLine3 ?*/}
          {/*    <span>*/}
          {/*    <br/>*/}
          {/*    <br/>*/}
          {/*    {faq.answerLine3}*/}
          {/*    </span> : null*/}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });
  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
